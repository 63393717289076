@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cabin&display=swap');

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration-line: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

@keyframes blink-animation {
  50% {
    opacity: 0.0;
  }
}

.hideScrollbar::-webkit-scrollbar,
.hideScrollbar *::-webkit-scrollbar {
  display: none;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn svg.rs-picker-toggle-caret {
  top: 7px;
}

.monospace {
  font-family: monospace;
}